import React, { useState, useEffect } from 'react';
import { apiJSONURL } from './LiveCasino';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';



const promptpayBtn = <div id="promptpayBtn"><img src="/images/promptpay-icon.webp" alt="promptpay"/>PromptPay</div>;
const truewalletBtn = <div id="truewalletBtn"><img src="/images/truewallet-icon.webp" alt="truewallet"/>True Wallet</div>;
const bankBtn = <div id="dragonPayBtn"><img src="/images/bank-icon.webp" alt="DragonPay"/>Bank Account</div>;
const banktransferBtn = <div id="truewalletBtn"><img src="/images/banktransfer-icon.webp" alt="bank"/>Bank Transfer</div>;


const Deposit = () => {
  const { t, i18n } = useTranslation();
  const setting = true;

  const defaultVisibleId = 'promptpay';

  const getDefaultMethod = (id) => {
    if (id === 'truewallet') {
      return 'truewallet';
    } else if (id === 'promptpay') {
      return 'promptpay';
    } else if (id === 'banktransfer') {
      return 'banktransfer';
    } else if (id === 'Bank')
    {
      return 'bank';
    }
    return null;
  };

  const getReturnUrl = () => {
    return `${window.location.protocol}//${window.location.hostname}/account/request-report/`;
  };

  const banks = [
    {value:"-1", img:"/images/bank icons/no-bank.webp",bankName:t('bank.29')},
    { value: "KKR", img: "/images/bank icons/KBANK.webp", bankName: t('bank.26')  },
   { value: "BBL", img: "/images/bank icons/BBL.webp", bankName: t('bank.27') },
   { value: "BOA", img: "/images/bank icons/BAY.webp", bankName: t('bank.28')  },
 ];

  const handleSelectChange = (e) => {
    const selectedBank = e.target.value;
    setMbc(selectedBank);

    const selectedBankData = banks.find(bank => bank.value === selectedBank);
    setBankIcon(selectedBankData ? selectedBankData.img : '');
  };
  const [bankIcon, setBankIcon] = useState('/images/bank icons/no-bank.webp');
  const [visibleId, setVisibleId] = useState(defaultVisibleId);
  const [amt, setAmount] = useState('');
  const [mbc, setMbc] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [bonusId, setBonusId] = useState('-1');
  const [method, setMethod] = useState(getDefaultMethod(defaultVisibleId));
  const [bonusList, setBonusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minValue, setMinValue] = useState('');
  const [selectedProcId, setSelectedProcId] = useState('');
  const [minValueDp, setMinValueDp] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const fetchBonusList = async () => {
      const username = localStorage.getItem('username');

      if (username) {
        try {
          const response = await axios.post(apiJSONURL,
            qs.stringify({
              cmd: 'getBonusList',
              u: username
            }), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );

          const data = response.data;
          if (setting) {
            if (data.ifDeposited === false) {
              setMinValue('101');
            } else {
              setMinValue('101');
            }
          }

          const bonusData = response.data.bonus;
          if (bonusData) {
            setBonusList(bonusData);
            setBonusId(bonusData[0].bonusid); // Set default value to the first bonus in the list
          } else {
            console.error('Bonus data not found in the response:', response.data);
          }
        } catch (error) {
          console.error('Error fetching bonus list:', error);
        }
      } else {
        console.error('Username not found in local storage');
      }
    };

    fetchBonusList();
  }, []);
  

  const checkDepositBonus = (bonusId, amount) => {
    let isError = false;
    let message = '';
    if (bonusId == 10) {
      if (amount != 500) { 
        message = "Invalid amount, only valid for 500 deposit.";
        isError = true;
      }
    } else if (bonusId == 11) {
      if (amount != 1000) { 
        message = "Invalid amount, only valid for 1000 deposit.";
        isError = true;
      }
    } else if (bonusId == 12) {
      if (amount != 2000) { 
        message = "Invalid amount, only valid for 2000 deposit.";
        isError = true;
      }
    } else if (bonusId == 13) {
      if (amount < 3000) { 
        message = "Invalid amount, only valid for 3000 and above deposit.";
        isError = true;
      }
    }
    setAlertMessage(message);
    return isError;
  };

  const handleDepositClick = (id) => {
    setVisibleId(id);
    setMethod(getDefaultMethod(id));
    setAmount('');
    setBonusId(bonusList.length > 0 ? bonusList[0].bonusid : '-1'); // Set to first bonus or '-1' if no bonuses are available
    setAlertMessage('');
  };

  const handleSubmit = async (additionalPayload = {}) => {
    setIsDisabled(true);
    const username = localStorage.getItem('username');
    const amount = parseFloat(amt);
    const selectedBonusId = parseInt(bonusId);

    if(!amt){
      setAlertMessage(`Please input deposit amount`);
      setIsDisabled(false);
      return;
    }
    if (checkDepositBonus(selectedBonusId, amount)) {
      return;
    }
  
    setLoading(true);
    setAlertMessage('');
  
    try {
      const response = await axios.post(apiJSONURL, 
        qs.stringify({
          cmd: 'deposit-request',
          method,
          u: username,
          returnUrl: getReturnUrl(),
          amt: amount,
          bonusid: selectedBonusId,
          ...additionalPayload
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
  
      console.log('API response:', response);
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        setAlertMessage(response.data.emsg);
      }
    } catch (error) {
      console.error('API call error:', error);
      setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
    } finally {
      setLoading(false);
      setIsDisabled(false);
    }
  };

  const handletruewalletSubmit = async () => {
    const amount = parseFloat(amt);
    if (amount < parseFloat(minValue)) {
      setAlertMessage(`Amount must be at least ${minValue}`);
      setIsDisabled(false);
      return;
    }
    await handleSubmit();
  };

  const handlepromptpaySubmit = async () => {
    const amount = parseFloat(amt);
    if (amount < 101) {
      setAlertMessage(`Amount must be at least 101`);
      return;
    }
    await handleSubmit();
  };

  const handlebanktransferSubmit = async () => {
    const amount = parseFloat(amt);
    if (amount < parseFloat(minValue)) {
      setAlertMessage(`Amount must be at least ${minValue}`);
      setIsDisabled(false);
      return;
    }
    await handleSubmit();
  };

  const handleBankSubmit = async () => {
    const amount = parseFloat(amt);
    if (amount < 300) {
      setAlertMessage(`Amount must be at least 300`);
      return;
    }
    if (mbc== "" ||mbc=='-1')
    {
      setAlertMessage(`No bank selected`);
      return;
    }
    
    await handleSubmit({ mbc: mbc });
  };

  return (
      <>
        <div className="account-container">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>{t('titles.4')}</h1>
            </div>
          </div>
          <div className="deposit-container">
          <div className="depositnav-nav nav-tabs">
            <a className={`${visibleId === 'promptpay' ? 'active' : ''}`} onClick={() => handleDepositClick('promptpay')}>{promptpayBtn}</a>
            <a className={`${visibleId === 'truewallet' ? 'active' : ''}`} onClick={() => handleDepositClick('truewallet')}>{truewalletBtn}</a>
            <a className={`${visibleId === 'Bank' ? 'active' : ''}`} onClick={() => handleDepositClick('Bank')}>{bankBtn}</a>
            <a className={`${visibleId === 'banktransfer' ? 'active' : ''}`} onClick={() => handleDepositClick('banktransfer')}>{banktransferBtn}</a>
          </div>
          <div className="all-form-container">

          {visibleId === 'promptpay' && <form id="promptpay">
            <div className="form-title">{promptpayBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>{t('notice.2')}: </strong> {t('notice.1')}</div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.1')}</div>
                <div className="input-container">
                  <input id="promptpay-amount" className="form-control txt-amount" autoComplete="off" max="50000" maxLength="20" min='101' name="amt" required="required" type="number" placeholder={`Min:101, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">{t('label.2')}</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList && bonusList
                      .filter((bonus) => bonus.bonusid !== '1')
                      .map((bonus) => (
                        <option value={bonus.bonusid} key={bonus.bonusid}>
                          {bonus.bonusnameEN}
                        </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handlepromptpaySubmit} disabled={isDisabled}>{t('header.7')}</button>
                </div>
              </div>
            </div>
            </form>}

            {visibleId === 'truewallet' && <form id="truewallet">
            <div className="form-title">{truewalletBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>{t('notice.2')}: </strong> {t('notice.1')}</div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.1')}</div>
                <div className="input-container">
                  <input id="truewallet-amount" className="form-control txt-amount" autoComplete="off" max="50000" maxLength="10" min={minValue} name="amt" required="required" type="number" placeholder={`Min:${minValue}, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">{t('label.2')}</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList && bonusList
                      .filter((bonus) => bonus.bonusid !== '1')
                      .map((bonus) => (
                        <option value={bonus.bonusid} key={bonus.bonusid}>
                          {bonus.bonusnameEN}
                        </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handletruewalletSubmit} disabled={isDisabled}>{t('header.7')}</button>
                </div>
              </div>
            </div>
            </form>}

            {visibleId === 'banktransfer' && <form id="banktransfer">
            <div className="form-title">{banktransferBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>{t('notice.2')}: </strong>{t('notice.1')}</div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.1')}</div>
                <div className="input-container">
                  <input id="bank-amount" className="form-control txt-amount" autoComplete="off" max="50000" maxLength="101" min={minValue} name="amt" required="required" type="number" placeholder={`Min:${minValue}, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">{t('label.2')}</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList && bonusList
                      .filter((bonus) => bonus.bonusid !== '1')
                      .map((bonus) => (
                        <option value={bonus.bonusid} key={bonus.bonusid}>
                          {bonus.bonusnameEN}
                        </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handlebanktransferSubmit} disabled={isDisabled}>{t('header.7')}</button>
                </div>
              </div>
            </div>
            </form>}

            {visibleId === 'Bank' && <form id="Bank">
            <div className="form-title">{bankBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>{t('notice.2')}: </strong>{t('notice.1')}</div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.3')}</div>
                <div className="input-container">

                                    <div className='bankselection'>
                    <img bankicon src={bankIcon} alt="Bank Icon" />
                    <select value={mbc} onChange={handleSelectChange} name="mbc" className="form-control">
                      {banks.map(bank => (
                        <option key={bank.value} value={bank.value}>
                          {bank.bankName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.1')}</div>
                <div className="input-container">
                  <input id="bank-amount" className="form-control txt-amount" autoComplete="off" max="500000" maxLength="101" min='300' name="amt" required="required" type="number" placeholder={`Min:${300}, Max:500,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">{t('label.2')}</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList && bonusList
                      .filter((bonus) => bonus.bonusid !== '1')
                      .map((bonus) => (
                        <option value={bonus.bonusid} key={bonus.bonusid}>
                          {bonus.bonusnameEN}
                        </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handleBankSubmit} disabled={isDisabled}>{t('header.7')}</button>
                </div>
              </div>
            </div>
            </form>}

          </div>
          {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
          </div>
        </div>
      </>
  );
};
export default Deposit;